<template>
  <vue-line type="body">
    <vue-item align="right">{{ index }}</vue-item>
    <vue-item>
      <div class="segments-table__name">
        <a
            :href="item.name"
            class="segments-table__link"
        >
          {{ item.name }}
        </a>
        <button
            class="segments-table__copy"
            v-tippy
            content="Скопировать URL"
            v-clipboard="item.name"
        >
          <icon name="copy"/>
        </button>
      </div>
    </vue-item>
    <vue-item align="right">
      {{ $moment(item.datetime).format(DATE_FORMAT.DAY_MONTH_SHORT_YEAR_DOT) }}
    </vue-item>
    <vue-item align="center">
      <v-button
          class="segments-table__tools-button"
          type="text-base"
          icon="delete"
          v-tippy
          :content="$t(contentPathSegmentsCatalog)['Delete']"
          @click="confirmDeletion"
      />
      <v-dialog
          v-if="showDialog"
          v-bind="dialogProps"
      />
    </vue-item>
  </vue-line>
</template>

<script>
import {mapActions} from 'vuex'

import {DATE_FORMAT, NOTIFY_STATUS} from '@/const'

const contentPathSegmentsCatalog = 'services.segments.catalog'

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },

    segmentId: {
      required: true
    },

    activeProjectId: {
      required: true
    },

    index: {
      type: Number,
      default: 0
    },

    catalogId: {
      type: Number,
      required: true
    },

    segmentName: {
      type: String,
      required: true
    }
  },

  data: () => ({
    contentPathSegmentsCatalog,

    DATE_FORMAT,

    showDialog: false
  }),

  computed: {
    dialogProps () {
      return {
        html: `${this.$t(contentPathSegmentsCatalog)['Are you sure you want to delete']} <strong>${this.item.name}</strong>?`,
        buttonTextConfirm: this.$t(contentPathSegmentsCatalog)['Delete'],
        actionDelete: true,
        onConfirm: () => {
          this.removeSegmentItems({
            query: {
              project_id: this.activeProjectId,
              skip_regionality: 1
            },
            body: {
              segment_name: this.segmentName,
              catalog_id: this.catalogId,
              items: [this.item.id]
            }
          })
              .then(_ => {
            this.$notify({
              type: NOTIFY_STATUS.SUCCESS,
              title: this.$t(contentPathSegmentsCatalog)['The task to delete the element "${item.name}" is set'].replace('${item.name}', this.item.name)
            })
          })
              .catch(({message}) => {
            this.$notify({
              type: NOTIFY_STATUS.ERROR,
              title: message
            })
          })
              .finally(() => this.clearDialogData())
        },
        onDismiss: this.clearDialogData
      }
    }
  },

  methods: {
    ...mapActions({
      removeSegmentItems: 'segments/catalog/item/removeSegmentItems'
    }),

    confirmDeletion () {
      this.showDialog = true
    },

    clearDialogData () {
      this.showDialog = false
    }
  }
}
</script>

<style scoped lang="scss" src="../item.scss"/>
