import { render, staticRenderFns } from "./itemTable.vue?vue&type=template&id=360dae9c&scoped=true"
import script from "./itemTable.vue?vue&type=script&lang=js"
export * from "./itemTable.vue?vue&type=script&lang=js"
import style0 from "../item.scss?vue&type=style&index=0&id=360dae9c&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "360dae9c",
  null
  
)

export default component.exports