<template>
  <vue-table
      small-padding
      head-background
      class="segments-table"
      :hoverable-rows="false"
  >
    <template #head>
      <vue-line type="head">
        <vue-item>#</vue-item>
        <vue-item>URL страницы</vue-item>
        <vue-item>Дата</vue-item>
        <vue-item align="center">
          {{ $t(contentPathSegmentsCatalog)['Delete'] }}
        </vue-item>
      </vue-line>
    </template>

    <template #body>
      <v-table-line
        v-for="(item, i) in data.items"
        :key="i"
        v-bind="{
          item,
          segmentId,
          activeProjectId,
          index: i + 1,
          catalogId,
          segmentName
        }"
      />
    </template>
  </vue-table>
</template>

<script>
import tableLine from './tableLine'

const contentPathSegmentsCatalog = 'services.segments.catalog'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    segmentId: {
      required: true
    },

    activeProjectId: {
      required: true
    },

    catalogId: {
      type: Number,
      required: true
    },

    segmentName: {
      type: String,
      required: true
    }
  },

  components: {
    'v-table-line': tableLine
  },

  data: () => ({
    contentPathSegmentsCatalog
  })
}
</script>

<style scoped lang="scss" src="../item.scss"/>
