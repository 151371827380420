import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import {decline, generateTitle, findOptions} from '@/function'
import updateUrl from '@/mixins/updateUrl'
import {required, minLength, maxLength} from 'vuelidate/lib/validators'

import CONSTANTS from '@/const/'
import ROUTE_NAME from '@/router/routeName'

import error from '@/services/errors/block.vue'
import container from '@/services/components/container/'
import itemTable from './components/itemTable'
import noData from '@/services/errors/noData'

const contentPathSegmentsCatalog = 'services.segments.catalog'

export default {
  mixins: [
    updateUrl
  ],

  metaInfo () {
    return {
      title: generateTitle(this.title.meta, this.activeProjectData)
    }
  },

  data () {
    return {
      contentPathSegmentsCatalog,

      preloader: {
        app: false,
        data: false
      },

      filters: {
        name: ''
      },

      newCatalogId: null,
      newName: null,

      editNameFlag: false,

      ROUTE_NAME,
      CONSTANTS,

      showDialog: false,
      idSegmentToMove: undefined
    }
  },

  validations: {
    newName: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(255)
    }
  },

  components: {
    'v-error': error,
    'v-container': container,
    'v-item-table': itemTable,
    'v-no-data': noData
  },

  watch: {
    $route () {
      /* Установка фильтров */
      this.getDataList()
    }
  },

  async created () {
    await this.initData()
  },

  computed: {
    ...mapState({
      segment: state => state.segments.catalog.item.segment.data,
      data: state => state.segments.catalog.item.data,
      activeProjectData: state => state.active_project_data,
      segmentListData: state => state.segments.catalog.list.data
    }),

    /* Данные по каталогу */
    ...mapGetters({
      listCatalog: 'segments/catalog/listCatalog',
      itemCatalog: 'segments/catalog/itemCatalog',
      breadcrumbsCatalog: 'segments/catalog/breadcrumbsCatalog'
    }),

    /* ID активного проекта в URL */
    activeProjectId () {
      let id = +this.$route.params.activeProject
      return id ? +id : undefined
    },

    /* ID активного сегмента в URL */
    catalogId () {
      let id = +this.$route.params.catalogId
      return id ? +id : undefined
    },

    /* ID сегмента */
    segmentId () {
      let id = this.$route.params.segmentId
      return id ? id : undefined
    },

    /* Данные раздела каталога */
    itemCatalogData () {
      return this.itemCatalog(this.catalogId)
    },

    /* Хлебные кроки раздела каталога */
    breadcrumbsCatalogData () {
      return this.breadcrumbsCatalog(this.catalogId)
    },

    title () {
      let breadcrumbsList = this.breadcrumbsCatalogData
        ? JSON.parse(JSON.stringify(this.breadcrumbsCatalogData.breadcrumbs))
        : [{title: this.itemCatalogData.name}]
      let primary = breadcrumbsList.slice(-3).map(i => i.title).join(' / ')
      let secondary = this.segment.name
      let meta = `${breadcrumbsList.slice(-2).map(i => i.title).join(' / ')} / ${secondary}`

      let breadcrumbs = []
        .concat(breadcrumbsList)
        .concat([{title: secondary}])

      breadcrumbs.map(item => {
        switch (true) {
          case item.tab:
            item.path = {
              name: ROUTE_NAME.SEGMENTS_CONTENT_INDEX,
              params: {activeProject: this.activeProjectId},
              query: {catalog_id: item.id}
            }
            break

          case item.selected:
            item.path = {
              name: ROUTE_NAME.SEGMENTS_CONTENT_LIST,
              params: {
                activeProject: this.activeProjectId,
                catalogId: item.id
              }
            }
            break
        }
        return item
      })

      return {
        primary: primary,
        secondary: secondary,
        breadcrumbs: breadcrumbs,
        meta: meta
      }
    },

    catalog () {
      return this.listCatalog
    },

    viewPage () {
      return this.catalogId && this.segmentId
    },

    /* Фильтры страницы в URL */
    urlFilters () {
      let filters = {date: {}}
      let urlQuery = {...this.$route.query}

      filters.page = urlQuery.page || 1
      filters.limit = +urlQuery.limit
      filters.name = urlQuery.name

      return filters
    },

    countPages () {
      try {
        return Math.ceil(this.data.total / this.data.limit)
      } catch (_) {
        return 0
      }
    },

    decline () {
      return decline
    },

    dialogProps () {
      return {
        ref: 'dialogSegmentMovement',
        html: `${this.$t(contentPathSegmentsCatalog)['Are you sure you want to move the segment']} <strong>${this.segment.name}</strong>?`,
        buttonTextConfirm: this.$t(contentPathSegmentsCatalog)['Move'],
        onConfirm: () => {
          if (this.idSegmentToMove !== this.catalogId) {
            this.relocateSegment({
              segmentId: this.segmentId,
              query: {
                project_id: this.activeProjectId,
                skip_regionality: 23,
                catalog_id: this.catalogId
              },
              body: {catalog_id: this.newCatalogId}
            }).then(_ => {
              this.$notify({
                type: CONSTANTS.NOTIFY_STATUS.SUCCESS,
                title: this.$t(contentPathSegmentsCatalog)['The data has been updated']
              })
              this.$router.push({
                name: ROUTE_NAME.SEGMENTS_CONTENT_ITEM,
                params: {
                  activeProject: this.activeProjectId,
                  catalogId: id,
                  segmentId: this.segmentId
                }
              })
            })
                .catch(() => {
                  this.$notify({
                    type: CONSTANTS.NOTIFY_STATUS.ERROR,
                    title: this.$t(contentPathSegmentsCatalog)['An error occurred while moving the segment']
                  })
                })
                .finally(() => this.clearDialogData())
          } else {
            this.$notify({
              type: CONSTANTS.NOTIFY_STATUS.ERROR,
              title: this.$t(contentPathSegmentsCatalog)['The segment is already in this folder']
            })
            this.clearDialogData()
          }
        },
        onDismiss: () => {
          this.newCatalogId = String(this.catalogId)
          this.clearDialogData()
        }
      }
    }
  },

  methods: {
    ...mapActions({
      getSegmentData: 'segments/catalog/item/getSegmentData',
      getSegmentsItems: 'segments/catalog/item/getSegmentsItems',
      getList: 'segments/catalog/list/getList',
      relocateSegment: 'segments/catalog/item/relocateSegment',
      renameSegment: 'segments/catalog/item/renameSegment'
    }),

    ...mapMutations({
      setSegmentData: 'segments/catalog/item/setSegmentData'
    }),

    /* Изменение URL фильтров */
    updateFilters (filter, value) {
      switch (filter) {
        case 'limit':
          this.updateUrl({
            [filter]: value,
            page: undefined
          })
          break

        default:
          this.updateUrl({[filter]: value})
      }
    },

    async saveSegmentName () {
      await this.renameSegment({
        segmentId: this.segmentId,
        query: {
          project_id: this.activeProjectId,
          skip_regionality: 1,
          catalog_id: this.catalogId
        },
        body: {segment_name: this.newName}
      }).then(_ => {
        this.$notify({
          type: CONSTANTS.NOTIFY_STATUS.SUCCESS,
          title: this.$t(contentPathSegmentsCatalog)['The data has been updated']
        })
        this.editNameFlag ^= true
      }, error => {
        if (typeof error === 'string') {
          this.$notify({type: CONSTANTS.NOTIFY_STATUS.ERROR, title: error})
        } else {
          for (let key in error) {
            let errorItem = error[key]
            if (Array.isArray(errorItem)) {
              errorItem.forEach(item => {
                this.$notify({type: CONSTANTS.NOTIFY_STATUS.ERROR, title: item})
              })
            }
          }
        }
      })
    },

    async getDataList (format) {
      if (!format) {
        this.preloader.app = true

        if (this.urlFilters.limit) {
          await this.$set(this.data, 'limit', this.urlFilters.limit)
        }
      }

      try {
        await this.getSegmentsItems({
          segmentId: this.segmentId,
          query: {
            project_id: this.activeProjectId,
            catalog_id: this.catalogId,
            skip_regionality: 1
          }
        })
      } catch ({message}) {
        this.$notify({type: CONSTANTS.NOTIFY_STATUS.ERROR, title: message})
      }

      if (!format) {
        this.preloader.app = false
      }
    },

    async initData () {
      if (this.viewPage) {
        this.preloader.app = true

        // await this.getSegmentData({
        //   catalogId: this.catalogId,
        //   query: {
        //     id: this.segmentId,
        //     project_id: this.activeProjectId,
        //     type: this.typeId
        //   }
        // })

        if (!this.segment.has_data) {
          await this.getList({
            catalogId: this.catalogId,
            query: {
              project_id: this.activeProjectId
            }
          })
              .then(() => {
                const segment = findOptions(this.segmentId, this.segmentListData.items)

                if (segment) {
                  this.setSegmentData({
                    segment_id: this.segmentId,
                    has_data: true,
                    data: segment
                  })
                }
              })
        }

        /* Установка фильтров */
        this.$set(this, 'filters', {...this.urlFilters})

        this.$set(this, 'newCatalogId', String(this.catalogId))

        /* Загрузка данных таблицы сегментов */
        await this.getDataList()

        this.preloader.app = false
      }
    },

    confirmSegmentRelocation (id) {
      this.showDialog = true
      this.idSegmentToMove = id
    },

    clearDialogData () {
      this.showDialog = false
      this.idSegmentToMove = undefined
    }
  }
}
