var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"dashboard-container catalog _column"},[_c('v-page-title',{attrs:{"primary":_vm.title.primary,"secondary":_vm.title.secondary,"breadcrumbs":"","custom-route-list":_vm.title.breadcrumbs}}),_c('div',{staticClass:"_px-32 _pb-16 _d-flex _justify-start _border-bottom"},[_c('v-button',{attrs:{"to":{ name: _vm.ROUTE_NAME.SEGMENTS_CONTENT_LIST },"icon":"chevron_left","type":"text-main","dataforce":""}},[_vm._v(" "+_vm._s(_vm.$t(_vm.contentPathSegmentsCatalog)['Back to segment selection'])+" ")])],1),(_vm.preloader.app)?_c('div',{staticClass:"dashboard-block"},[_c('v-preloader',{staticStyle:{"height":"calc(100vh - 300px)"}})],1):(_vm.viewPage && _vm.data.has_data)?[_c('div',{staticClass:"dashboard-wrapper _mt-28 _border-none"},[_c('div',{staticClass:"dashboard-block _mb-28 segments-about _ha"},[_c('div',{staticClass:"segments-about__col"},[(_vm.editNameFlag)?[_c('s-input',_vm._b({model:{value:(_vm.newName),callback:function ($$v) {_vm.newName=$$v},expression:"newName"}},'s-input',{
                    placeholder: _vm.$t(_vm.contentPathSegmentsCatalog)['Segment name'],
                    error: _vm.$v.newName.$invalid,
                    maxWidth: '200px'
                  },false)),_c('div',{staticClass:"segments-about__actions"},[_c('v-button',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"segments-about__edit",attrs:{"type":"text-base","positionIcon":"center","icon":"clear","content":_vm.$t(_vm.contentPathSegmentsCatalog)['Cancel']},on:{"click":function($event){_vm.editNameFlag ^= true}}}),_c('v-button',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"segments-about__edit",attrs:{"type":"text-base","positionIcon":"center","icon":"done","content":_vm.$t(_vm.contentPathSegmentsCatalog)['Save'],"disabled":_vm.$v.newName.$invalid},on:{"click":_vm.saveSegmentName}})],1)]:[_c('div',{staticClass:"segments-about__title"},[_vm._v(_vm._s(_vm.segment.name))]),_c('v-button',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"segments-about__actions segments-about__edit",attrs:{"type":"text-base","position-icon":"center","icon":"mode","content":_vm.$t(_vm.contentPathSegmentsCatalog)['Edit']},on:{"click":function($event){_vm.editNameFlag ^= true, _vm.newName = _vm.segment.name}}})]],2),(_vm.data.total)?_c('div',{staticClass:"segments-about__col"},[_vm._v(" "+_vm._s(_vm.countPages)+" "+_vm._s(_vm.decline(_vm.countPages, _vm.$t(_vm.contentPathSegmentsCatalog)['pages']))+" ")]):_vm._e(),_c('div',{staticClass:"segments-about__col"},[_c('s-treeselect',_vm._b({on:{"input":_vm.confirmSegmentRelocation},model:{value:(_vm.newCatalogId),callback:function ($$v) {_vm.newCatalogId=$$v},expression:"newCatalogId"}},'s-treeselect',{
                  insideLabel: _vm.$t(_vm.contentPathSegmentsCatalog)['Directory folder'],
                  options: _vm.catalog.items,
                  search: true
                },false))],1)]),_c('div',{staticClass:"dashboard-block _mb-32"},[_c('v-container',_vm._b({scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('div',{staticClass:"segments-tools"},[_c('ul',{staticClass:"segments-tools__counts"},[_c('li',[_vm._v(" Всего страниц: "),_c('span',[_vm._v(_vm._s(_vm.segment.url_count))])]),_c('li',[_vm._v(" "+_vm._s(_vm.$t(_vm.contentPathSegmentsCatalog)['Date'])+": "),_c('span',[_vm._v(_vm._s(_vm.$moment(_vm.segment.datetime).format(_vm.CONSTANTS.DATE_FORMAT.DAY_MONTH_SHORT_YEAR_DOT)))])])])])]},proxy:true}])},'v-container',{
                load: _vm.preloader.data,
                hasData: _vm.data.has_data
              },false),[_c('v-item-table',_vm._b({},'v-item-table',{
                  data: _vm.data,
                  segmentId: _vm.segmentId,
                  activeProjectId: _vm.activeProjectId,
                  catalogId: _vm.catalogId,
                  segmentName: _vm.segment.name
                },false)),_c('v-pagination',{attrs:{"showCountElement":true,"showLimitSelector":true,"pageNumber":_vm.$route.query.page,"limit":_vm.data.limit,"count":_vm.data.total},on:{"limit":function($event){return _vm.updateFilters('limit', $event)}}})],1)],1)])]:(!_vm.data.has_data)?_c('v-no-data'):_c('v-error',{attrs:{"title":_vm.$t('modules.pages')['Error'],"subtitle":_vm.$t('modules.pages')['No access to projects']}}),(_vm.showDialog)?_c('v-dialog',_vm._b({},'v-dialog',_vm.dialogProps,false)):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }