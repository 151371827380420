var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-table',{staticClass:"segments-table",attrs:{"small-padding":"","head-background":"","hoverable-rows":false},scopedSlots:_vm._u([{key:"head",fn:function(){return [_c('vue-line',{attrs:{"type":"head"}},[_c('vue-item',[_vm._v("#")]),_c('vue-item',[_vm._v("URL страницы")]),_c('vue-item',[_vm._v("Дата")]),_c('vue-item',{attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.contentPathSegmentsCatalog)['Delete'])+" ")])],1)]},proxy:true},{key:"body",fn:function(){return _vm._l((_vm.data.items),function(item,i){return _c('v-table-line',_vm._b({key:i},'v-table-line',{
        item,
        segmentId: _vm.segmentId,
        activeProjectId: _vm.activeProjectId,
        index: i + 1,
        catalogId: _vm.catalogId,
        segmentName: _vm.segmentName
      },false))})},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }